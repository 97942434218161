<template>
    <div>
        <div class="survey-navbar-container sticky-md-top">
            <div class="survey-navbar">
                <ul class="survey-nav me-auto">
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/details`" class="survey-nav-link"
                                     title="View a summary of your survey">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="info-circle"/></span>
                            <span class="d-none d-lg-inline-block">Summary</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/questionnaire`" class="survey-nav-link"
                                     :title="!survey.published ? 'Edit your form' : 'View your form'">
                            <template v-if="!survey.published">
                                <span class="survey-nav-link-icon"><font-awesome-icon icon="pen"/></span>
                                <span class="d-none d-lg-inline-block">Edit Form</span>
                            </template>
                            <template v-else-if="survey.published">
                                <span class="survey-nav-link-icon"><font-awesome-icon icon="file-alt"/></span>
                                <span class="d-none d-lg-inline-block">View Form</span>
                            </template>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/respondents`" class="survey-nav-link"
                                     title="View your survey responses">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="comment-dots"/></span>
                            <span class="d-none d-lg-inline-block">Responses</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/reports`" class="survey-nav-link"
                                     title="View a detailed report of your survey">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="chart-bar"/></span>
                            <span class="d-none d-lg-inline-block">Reports</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/logs`" class="survey-nav-link">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="list"/></span>
                            <span class="d-none d-lg-inline-block">Logs</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/map`" class="survey-nav-link">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="map"/></span>
                            <span class="d-none d-lg-inline-block">Map</span>
                        </router-link>
                    </li>
                </ul>
                <ul class="survey-nav ms-auto">
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/settings`" class="survey-nav-link"
                                     title="Adjust your survey settings">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="cog"/></span>
                            <span class="d-none d-lg-inline-block">Settings</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/share`" class="survey-nav-link"
                                     title="Share your survey">
                            <span class="survey-nav-link-icon"><font-awesome-icon icon="share-alt"/></span>
                            <span class="d-none d-lg-inline-block">Share</span>
                        </router-link>
                    </li>
                    <li class="survey-nav-item">
                        <router-link :to="`/profile/surveys/view/${this.code}/preview`" class="survey-nav-link"
                                     title="Preview or publish your survey">
                            <template v-if="!survey.published">
                                <span class="survey-nav-link-icon"><font-awesome-icon icon="paper-plane"/></span>
                                <span class="d-none d-lg-inline-block">Preview &amp; Publish</span>
                            </template>
                            <template v-else>
                                <span class="survey-nav-link-icon"><font-awesome-icon icon="eye"/></span>
                                <span class="d-none d-lg-inline-block">Preview</span>
                            </template>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <router-view :code="code"></router-view>
    </div>
</template>

<script>

export default {
    name: "Survey",
    props: ['code'],
    data() {
        return {
            survey: {},
            permissions: []
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
        })
        this.$http.get("/api/users/manage/" + this.auth.id).then(response => {
            this.permissions = response.data.data.permissions
        })
    },
    computed: {
        auth() {
            return JSON.parse(localStorage.getItem(process.env.VUE_APP_NAME + '_user'))
        },
    },
    methods: {
        can(permission) {
            let check = this.permissions.find(a => a.name == permission)
            return check !== undefined
        }
    }
}
</script>

<style scoped>

</style>